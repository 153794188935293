.radiology-observation-flex {
  display: flex !important;
  gap: 3%;
  margin-bottom: 10px !important;
  align-items: center;
}
.radiology-collect-header {
  margin: 2px 0;
  font-size: 14px;
  font-weight: 500;
}
.h30px {
  height: 30px;
}
.radiology-obs-area {
  resize: none;
}
.obs-pointer {
  cursor: pointer;
}
.radiology-input-error {
  border: 2px solid red;
}
.h29px {
  height: 29px;
}
.w60px {
  width: 60px;
}
.w380px {
  width: 380px;
}
.w165 {
  width: 165px;
}
.w-32 {
  width: 32%;
}
.w-55 {
  width: 55%;
}
.w-100 {
  width: 100%;
}
.radiology-observation-input {
  // margin-left: 27%;
  overflow: hidden;
  // position: relative; /* Ensures that the textarea's overflow doesn't affect surrounding elements */
  // border: 1px solid #ccc; /* Border for the container, if needed */
  // padding: 5px; /* Add some padding to ensure space around the textarea */
  // overflow: hidden;
  width: 50%;
}
.radiology-noted-area {
  width: 100%; /* Ensures the textarea takes full width */
  resize: none; /* Disable manual resizing */
  border: 1px solid #ccc; /* Keep the border consistent */
  // box-sizing: border-box;
}
.radiology-auto-height {
  height: auto;
}
.radiology-hidden-overflow {
  overflow: hidden;
}
.radiology-obs-flex {
  display: flex;
  margin-left: 67px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radiology-obs-container {
  border: 1px solid rgba(76, 76, 76, 0.6);
  margin-right: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 6px;
  border-color: none;
}
.radioogy-obs-span-title {
  font-size: 13px;
  font-weight: 500;
  word-break: break-word;
}
.radiology-obs-span-content {
  font-size: 12px;
  font-weight: 300;
  border-color: none;
  word-break: break-word;
}
.radiology-summary-container {
  //  margin-left: -45px;
  display: flex;
  gap: 5px;
}

.radiology-summary-span-container {
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0px;
}
.radiology-obs-delete {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  background-color: #232323;
  color: white;
  border-radius: 50%;

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
}

.radiology-obs-title {
  font-size: 13px;
  font-weight: 500;
}

.radiology-obs-content {
  font-size: 12px;
  font-weight: 300;
}
.radiology-observation-container {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.title-area {
  height: auto;
  overflow: hidden;
  width: 95%;
  border-radius: 5px;
  background-color: #d5d2d2;
  padding: 5px;
}
.content-area {
  height: auto;
  overflow: hidden;
  width: 95%;
  border-radius: 5px;
  padding: 5px;
}
.radiology-title-body {
  padding: 8px;
  border: 1px solid #ccc;
  width: 40%;
  font-size: 15px;
}
.radiology-title-expand {
  padding: 8px;
  border: 1px solid #ccc;
  width: 55%;
}
.obs-word-break {
  word-break: break-all;
}
.obs-title-expand {
  border: none;
  color: blue;
  cursor: pointer;
}
.obs-delete-content {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: center;
  width: 5%;
}
.obs-delete-btn {
  border: none;
  cursor: pointer;
  background: none;
}
.obs-delete-icon {
  color: #183f7c;
}
.obs-list-container {
  width: 100%;
  border-collapse: collapse;
  border-radius: 14px;
}
.summary-results {
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 10;
}
.results-submit {
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 4%;
}
.comment-section {
  border-radius: 5px;
  padding: 5px;
}
.radiology-obs-button {
  height:29px;
  width:60px
}
