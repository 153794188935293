.filter-dropdown {
  position: relative;
  z-index: 10;
  display: flex;
  height: 25px;
  background-color: #e9ecef;
  margin-right: 1rem;
}
.filter-button {
  font-size: 10px !important;
  border-radius: 0px;
  
}

.filter-button:hover {
  background-color: #cecece !important;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 11;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500px;
}
.menu1:hover,
.menu2:hover {
  background-color: lightgray;
}
.menu1 {
  padding: 8px 14px;
  cursor: pointer;
}

.submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4;
}

.submenu div {
  padding: 8px 14px;
  text-decoration: none;
  color: #000;
}

.menu2 {
  padding: 8px 14px;
  text-decoration: none;
  color: #000;
}
.menu3 {
  padding: 8px 14px;
  text-decoration: none;
  color: #000;
}
