.category-filter {
  width: 115px;
  padding: 2% !important;
  height: 30px;
}
.no-Action {
  display: none;
}
.head-action {
  position: sticky;
  top: 0px;
}
.Samples {
  padding: 6;
  display: flex;
}

.handle-call {
  position: relative;

  img {
    position: absolute;
    z-index: 0;
    width: 18px;
    right: 18px;
    top: 6px;
    cursor: pointer;
  }
}
.radiology-referred-expand-content {
  width: 40%;
  display: flex;
}
.w25 {
  width: 25%;
}
.radiology-test-flex {
  width: 30%;
  display: flex;
  justify-content: end;
  gap: 20px;
}
.radiology-submitted {
  width: 25%;
  display: flex;
  justify-content: end;
  gap: 20px;
}
.radiology-submitted-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}
.radiology-approve {
  display: flex;
  gap: 8px;
}
.radiology-test-group-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.radiology-tests {
  margin-top: 12px;
  min-width: 300px;
}
.radiology-admin-header {
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0 8px;
}

.radiology-admin-select {
  padding: 4px;
  width: 115px;
  border: 1px solid #aeafb5;
  border-radius: 4px;
  background-color: white;
  height: 30px;

  &:focus-visible {
    outline: #aeafb5 solid 1px;
  }
}
.radiology-test-group-
.radiology-test-group-table {
  width: 100%;
  border-radius: 8px;
}
.radiology-test-group-table,
.radiology-test-group-table tr,
.radiology-test-group-table td,
.radiology-test-group-table th {
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
}
.radiology-test-group-table {
  width: 100%;
  font-size: 13px;
  border-spacing: 0;
  border: 1px solid #004c91;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  th {
    padding: 6px 12px;
  }

  thead {
    background-color: #4682b4;
    color: #ffffff;
    border: 1px solid #e6e6e6;

    tr {
      text-align: left;

      th {
        font-weight: 500;
      }

      th:first-child {
        border-top-left-radius: 4px;
      }

      th:last-child {
        border-top-right-radius: 4px;
      }
    }
  }

  tbody {
    .lms-btn {
      border: none;
      background-color: transparent;
      color: blue;
      text-decoration: underline;
      cursor: pointer;
      text-align: left;
      padding: 0;

      &:focus-visible {
        outline: #0088ff auto 1px;
      }
    }

    tr:nth-child(even) {
      background-color: #ebebeb;
    }
  }
}
.radiology-upload-image-container {
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.top-sticky {
  position: sticky;
  top: 0px;
}
.fs-12 {
  font-size: 12px;
}
.w30 {
  width: 30%;
}
.w13 {
  width: 13%;
}
.flex-content {
  display: flex;
}
.referred-result-content {
  color: black;
  text-align: center;
}
.referredgroup-result {
  color:black
}
.w8 {
  width: 8%;
}
.w10 {
  width: 10%;
}
.w13 {
  width: 13%;
}
.w15 {
  width: 15%;
}
.w20 {
  width: 20%;
}
.w30 {
  width: 30%;
}
.action-content {
  width: 20%;
  display: flex;
  color: black;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  border: none;
}
.check-status {
  display: flex;
  justify-content: end;
}
.saved-actions {
  display: flex;
  justify-content: flex-start;
}
.pointer {
  cursor: pointer;
}
.referred-saved-test {
  color: yellow;
  cursor: pointer;
}
.noreferred-test {
  color:brown;
  cursor: pointer;
}
.cursor-content {
  cursor:pointer
}
.saved-test {
  color:green;
  cursor:pointer
}
.no-submit-action {
  color:orange;
  cursor:pointer
}
.rejected-test {
  color:red;
  cursor:pointer
}
.approve-test-action {
  color:#183f7c;
  cursor: pointer;
}
.upload-actions {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 24%;
  align-items: center;
  margin-left: 10px;
}
.document-download {
  display: flex;
  justify-content: center;
  align-items: center;
}
.test-group {
  background-color: #b1b6bb;
  border-radius: 6px 6px 2px 2px;
  color: #f1f1f1;
  display: flex;
  margin: 4px 0 2px;
  padding: 5px;
}
.test-name {
  align-items: center;
  color: #000;
  display: flex;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin: 0 30px 0 0;
}
.expand-content {
  display: flex;
  width: 40%;
}
.check-content {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 35%;
}
.test-group > div {
  border-right: 1px solid #fff;
}
.test-group > div:last-child {
  border-right: none; /* Remove border from the last div */
}
.radiology-tab-group {
  padding-left: 10px;
  border-bottom: 1px solid #004c91;

  .radiology-tab-btn {
    padding: 5px 8px;
    margin-right: 10px;
    border: 1px solid #004c91;
    border-bottom: none;
    background-color: #ffffff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;

    &.active {
      background-color: #4682b4;
      color: #ffffff;
    }

    &:focus-visible {
      outline: black auto 1px;
    }
  }
}
.fs-20 {
  font-size: 20px;
}
.save-ctn {
  display: flex;
  gap: 10px;
}
.submit-ctn {
  display: flex;
  gap: 5px;
  align-items: center;
}
.doc-url {
  color: black;
}
.no-doc-url {
  color: #183f7c;
}
.w-100 {
  width: 100%;
}
.searched-view {
  justify-content: space-between;
}
.p0 {
  padding: 0px !important;
}
.radiology-referred-table {
  padding: 0px !important;
}
.test-doc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.radiology-flex {
  display: flex;
}
.test-icons {
  display: flex;
  justify-content: flex-end;
  width: 25%;
  align-items: center;
}
.obs-status {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.referred-out-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.referred-table {
  max-height: 88%;
}
.radiology-referred-head {
  position: sticky;
  top: 0px;
  z-index: 9;
}
.referred-pagination {
  display: flex;
  justify-content: flex-start;
}
.radiology-dashboard {
  height: 87%;
  width: 100%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5%;
}
.radiology-test-flow {
  overflow-y: auto;
}
.radiology-table-interpretation {
  word-wrap: break-word;
}
.radiology-table-toggle {
  color: blue;
  cursor: pointer;
}
.package-tests-text {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #8e8e8e;
}
.custom-text {
  font-size: 14px;
  color: #212121;
}
.select-test {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.radiology-package-form-container {
  margin-top:15px
}
.disabled-action {
  color:green
}
