.opd-container {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}
.opd-container-column {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.opd-menu {
  //  flex: 0 0 200px;

  ul {
    padding-left: 0;
    margin: 0;
    background-color: #4682b4;

    li {
      list-style-type: none;
      border-bottom: 1px solid #e6e6e6;

      &:last-child {
        border-bottom: none;
      }

      a {
        font-size: 12px;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        display: block;
        transition: 0.2s;

        &.active {
          background-color: #f8f8f8;
          color: #115797;
        }
      }
    }
  }
}

.opd-content {
  flex-grow: 1;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  //  background: brown;
  // justify-content: center;
  // align-items: center;
}
.opd-content-visit {
  flex-grow: 1;
  padding: 12px 8px;
  flex:1;
  overflow-y: scroll;
  margin-top: 1rem;
}

.opd-visittable {
  margin-top: -14px;
}
.opd-menudates {
  // background: black !important;
  display: flex;
  // flex-grow: 1;
  // width:100%
}
.opdmenu {
  margin-top: 2rem;
  min-width: 170px;
  flex-shrink: 0;
}
.date-appointment-filter {
  border: 1px solid #aeafb5;
  border-radius: 5px;
  background: white;
}

.time-picker-medications {
  .MuiInputBase-input {
    font-size: 12px;
    padding: 7px;
  }
  .MuiInputLabel-root {
    font-size: 12px;
  }
}

//Multi date picker styles
.b-deselect {
  margin-top: unset;
  padding: unset;
}

.b-date {
  margin-top: unset;
  padding: unset;
  font-size: 12px;
  white-space: nowrap;
}
.multi-date-picker-conatiner {
  width: 100%;
}

.custom-container-multi-date-picker {
  width: 100%;
}

.custom-container-multi-date-picker .rmdp-input {
  width: 100%;
  box-sizing: border-box;
  height: 30px;
}
//end
.doc-name {
  list-style-type: 'none';
  padding: 0;
  margin: 0;
}
.pointer-none {
  pointer-events: none;
}

.pointer-auto {
  pointer-events: auto;
}
.opdLayoutContent {
  width: 100%;
  padding: 0.8rem;
  height: 93vh;
  overflow-y: hidden !important;
}

.opd-height {
  height:93vh;
  overflow: hidden;
  width:100%
}
.opd-visit-details {
  margin-top: 0.5rem;
}
.opd-dates-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.flex-height-content {
  overflow: auto;
  flex: 1;
}
.dental-container {
  display: flex;
  flex-direction: column;
}
.flex-shrink {
  flex-shrink: 0 
}
.flex-one {
  flex:1
}
.opd-visit-container {
  margin-bottom:10px
}