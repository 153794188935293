@import url('./assets/css/style.scss');
body {
   background-color: #f8f8f8;
}

.ag-theme-alpine .ag-header-cell.custom-header-cell {
  color: #ffffff !important;
}
.ag-center-cols-viewport {
  min-height:30px !important
}
.ag-theme-alpine .ag-header {
  background-color: #4682b4 !important;
  // for some conditional use it is required
  // --ag-header-foreground-color: #ffffff;
}

.ag-theme-alpine .ag-icon-menu:before,
.ag-theme-alpine .ag-icon-asc:before,
.ag-theme-alpine .ag-icon-desc:before,
.ag-theme-alpine .ag-icon-filter:before {
  color: #ffffff;
}

.ag-theme-alpine {
  --ag-row-border-style: solid !important;
  --ag-row-border-width: 1px !important;
  --ag-row-border-color: #e6e6e6 !important;
  --ag-cell-horizontal-border: 1px solid #e6e6e6 !important;
}
.category-placeholder {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  color: #333;
}
.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.bold-category {
  font-weight: bold;
  margin-right: 5px;
}

.search-input {
  border: none;
  outline: none;
  flex: 1;
}
.total-width-content {
  width:100%
}
.total-height-content {
  height:100%
}