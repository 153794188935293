.headerComponent {
  background-color: #183f7c;
  color: #fff;
  display: flex;
  .headerComponent {
    background-color: #183f7c;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      padding: 6px 10px;
    }

    .location-data {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      select {
        padding: 3px;
        border-radius: 4px;
        background-color: #183f7c;
        color: #fff;
      }
      option {
        background-color: white;
        color: black;
      }
    }
  }
  .headerBodyComponnet {
    display: flex;
    align-items: center;
  }

  .sideBar {
    position: relative;

    ul {
      padding-left: 0;
      margin: 0;
      background-color: #0d3f6d;

      li {
        list-style-type: none;
        display: inline-block;

        a {
          font-size: 12px;
          color: #fff;
          text-decoration: none;
          padding: 10px;
          display: inline-block;
          background-color: #183f7c;

          &.active {
            background-color: #f8f8f8;
            color: #115797;
          }
        }
      }
    }
  }

  .pagination {
    margin-top: 20px;
    font-size: 13px;

    button {
      border: 0;
      background-color: transparent;
      padding: 10px;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .rowsperpage {
    border: 0;
    margin-right: 20px;
  }

  .disablePagBtn {
    pointer-events: none;
    opacity: 0.45;
  }

  .profile-dropdown {
    position: relative;
    .profile-btn {
      margin: 0 8px;
      background-color: transparent;
      border: none;
      font-size: 15px;
      font-weight: 600;
      color: #ffffff;
      display: flex;
      align-items: baseline;
      cursor: pointer;

      &:focus-visible {
        outline: #0088ff auto 1px;
      }

      .arrow {
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 6px;
        transition: transform 200ms ease-in-out;
      }

      .up {
        transform: rotate(-135deg) translate(-3px, 0px);
      }

      .down {
        transform: rotate(45deg) translate(0px, -3px);
      }
    }
    .profile-dropdown-logo {
      display: flex;
      align-items: center;
    }

    .logo-avatar {
      background-color: green;
      height: 25px;
      width: 25px;
      font-size: 18px;
      font-weight: 100;
      object-fit: cover;
    }
    .dropdown {
      z-index: 200;
      position: absolute;
      top: 24px;
      right: 10px;
      width: 90%;
      background-color: #ffffff;

      button {
        border: 1px solid #004c91;
        background-color: transparent;
        color: #004c91;
        font-weight: 500;
        width: 100%;
        padding: 4px 8px;
        cursor: pointer;
        transition: 0.3s;
      }

      &:hover button {
        border: 1px solid #fbfbfb;
        background-color: #004c91;
        color: #fbfbfb;
      }
    }
  }

  .selectable-search-wrapper {
    position: relative;

    .search-input {
      border: 1px solid #aeafb5;
      width: 300px;
      padding: 4px;
      border-radius: 4px;

      &::placeholder {
        font-size: 13px;
        font-style: italic;
      }
    }

    .options-wrapper {
      z-index: 9999999;
      position: absolute;
      top: 30px;
      width: 300px;
      border: 1px solid #004c91;

      .select-option {
        padding: 4px;
        margin: 0;
        cursor: pointer;
        background-color: #ffffff;
        font-size: 13px;

        &:not(:last-child) {
          border-bottom: 1px solid #004c91;
        }

        &.active {
          background-color: #004c91;
          color: #ffffff;
        }

        .select-value {
          font-style: italic;
          font-weight: bold;
        }
      }
    }
  }

  .provenance-view {
    text-align: left;

    .title {
      display: block;
      font-size: 10px;
      font-weight: 500;
    }

    .date {
      font-size: 12px;
      color: #808080;
    }

    .time {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .base-button {
    border: none;
    border-radius: 4px;
    background-color: #4682b4;
    color: #ffffff;

    &:hover {
      cursor: pointer;
      background-color: #004c91;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &:focus-visible {
      outline: black auto 1px;
      outline-offset: 2px;
    }

    &:active {
      transform: translateY(1px);
    }
  }

  .create-button {
    padding: 6px 12px;
  }

  .compact-button {
    font-size: 13px;
    padding: 4px 8px;
  }

  .edit-button {
    padding: 4px 6px;
    background-color: #4682b4;

    img {
      width: 18px;
    }
  }
  .dropdown-NameLogout {
    z-index: 200;
    position: absolute;
    top: 20px;
    right: 0px;
    width: 7rem;
    background-color: #ffffff;
    box-shadow: 0 1rem 2.1rem rgba(0, 0, 0, 0.2);
    margin-top: 0.7rem;
  }
  .dropdown-NameLogout p {
    color: #495057;
  }

  .dropdown-NameLogout button {
    border: 1px solid #004c91;
    background-color: transparent;
    color: #004c91;
    font-weight: 700;
    width: 100%;
    padding: 8px 8px;
    cursor: pointer;
    transition: 0.3s;
  }

  .dropdown-NameLogout button:hover {
    border: 1px solid #fbfbfb;
    background-color: #004c91;
    color: #fbfbfb;
  }
  .username-div {
    font-weight: 400;
    margin-top: 0rem;
  }
  .userAvatar-logo {
    height: 1.5rem;
    margin-right: 0.2rem;
  }

  justify-content: space-between;
  align-items: center;

  .logo {
    padding: 6px 10px;
  }

  .location-data {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    select {
      padding: 3px;
      border-radius: 4px;
      background-color: #183f7c;
      color: #fff;
    }
    option {
      background-color: white;
      color: black;
    }
  }
}
.headerBodyComponnet {
  display: flex;
  align-items: center;
}

.sideBar {
  position: relative;

  ul {
    padding-left: 0;
    margin: 0;
    background-color: #0d3f6d;

    li {
      list-style-type: none;
      display: inline-block;

      a {
        font-size: 12px;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        display: inline-block;
        background-color: #183f7c;

        &.active {
          background-color: #f8f8f8;
          color: #115797;
        }
      }
    }
  }
}

.pagination {
  margin-top: 20px;
  font-size: 13px;

  button {
    border: 0;
    background-color: transparent;
    padding: 10px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.rowsperpage {
  border: 0;
  margin-right: 20px;
}

.disablePagBtn {
  pointer-events: none;
  opacity: 0.45;
}

.profile-dropdown {
  position: relative;
  .profile-btn {
    margin: 0 8px;
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: baseline;
    cursor: pointer;

    &:focus-visible {
      outline: #0088ff auto 1px;
    }

    .arrow {
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      margin-left: 6px;
      transition: transform 200ms ease-in-out;
    }

    .up {
      transform: rotate(-135deg) translate(-3px, 0px);
    }

    .down {
      transform: rotate(45deg) translate(0px, -3px);
    }
  }

  .dropdown {
    z-index: 200;
    position: absolute;
    top: 24px;
    right: 10px;
    width: 90%;
    background-color: #ffffff;

    button {
      border: 1px solid #004c91;
      background-color: transparent;
      color: #004c91;
      font-weight: 500;
      width: 100%;
      padding: 4px 8px;
      cursor: pointer;
      transition: 0.3s;
    }

    &:hover button {
      border: 1px solid #fbfbfb;
      background-color: #004c91;
      color: #fbfbfb;
    }
  }
}

.selectable-search-wrapper {
  position: relative;

  .search-input {
    border: 1px solid #aeafb5;
    width: 300px;
    padding: 4px;
    border-radius: 4px;

    &::placeholder {
      font-size: 13px;
      font-style: italic;
    }
  }

  .options-wrapper {
    z-index: 9999999;
    position: absolute;
    top: 30px;
    width: 300px;
    border: 1px solid #aeafb5;

    .select-option {
      padding: 4px;
      margin: 0;
      cursor: pointer;
      background-color: #ffffff;
      font-size: 13px;

      &:not(:last-child) {
        border-bottom: 1px solid #aeafb5;
      }

      &.active {
        background-color: #004c91;
        color: #ffffff;
      }

      .select-value {
        font-style: italic;
        font-weight: bold;
      }
    }
  }
}

.provenance-view {
  text-align: left;

  .title {
    display: block;
    font-size: 10px;
    font-weight: 500;
  }

  .date {
    font-size: 12px;
    color: #808080;
  }

  .time {
    font-size: 13px;
    font-weight: 500;
  }
}

.base-button {
  border: none;
  border-radius: 4px;
  background-color: #004c91;
  color: #ffffff;

  &:hover {
    cursor: pointer;
    background-color: #004c91;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:focus-visible {
    outline: black auto 1px;
    outline-offset: 2px;
  }

  &:active {
    transform: translateY(1px);
  }
}

.create-button {
  padding: 6px 12px;
}

.compact-button {
  font-size: 13px;
  padding: 4px 8px;
}

.edit-button {
  padding: 4px 6px;
  background-color: #4682b4;

  img {
    width: 18px;
  }
}
.dropdown-NameLogout {
  z-index: 200;
  position: absolute;
  top: 20px;
  right: 0px;
  width: 7rem;
  background-color: #ffffff;
  box-shadow: 0 1rem 2.1rem rgba(0, 0, 0, 0.2);
  margin-top: 0.7rem;
}
.dropdown-NameLogout p {
  color: #495057;
}

.dropdown-NameLogout button {
  border: 1px solid #004c91;
  background-color: transparent;
  color: #004c91;
  font-weight: 700;
  width: 100%;
  padding: 8px 8px;
  cursor: pointer;
  transition: 0.3s;
}

.dropdown-NameLogout button:hover {
  border: 1px solid #fbfbfb;
  background-color: #004c91;
  color: #fbfbfb;
}
.username-div {
  font-weight: 400;
  margin-top: 0rem;
}
.userAvatar-logo {
  height: 1.5rem;
  margin-right: 0.2rem;
}
.arrow {
  margin-left: 12px;
  font-size: 10px;
}
.global-search-wrapper {
  position: relative;
  & .css-ittuaa-MuiInputAdornment-root {
    padding: 0px !important;

  }
  & .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0px !important;
  } 
  & .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root {
    width:300px;
    height:30px;
    padding-left:4px
  }
  & .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
    padding:0px !important; 
  }
  & .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
    padding:4px !important;
    font-size:12px
  }


  .search-input {
    border: 1px solid #aeafb5;
    width: 300px;
    padding: 4px;
    border-radius: 4px;

    &::placeholder {
      font-size: 13px;
      font-style: italic;
    }
  }

  .options-wrapper {
    z-index: 9999999;
    position: absolute;
    top: 30px;
    width: 300px;
    border: 1px solid #aeafb5;

    .select-option {
      padding: 4px;
      margin: 0;
      cursor: pointer;
      background-color: #ffffff;
      font-size: 13px;

      &:not(:last-child) {
        border-bottom: 1px solid #aeafb5;
      }

      &.active {
         background-color: #004c91;
        color: #ffffff;
      }

      .select-value {
        font-style: italic;
        font-weight: bold;
      }
    }
  }
}
.children-container {
  display: flex;
  flex:1
}
