.ipd-tabs-conatiner {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ipd-tabs-heading-conatiner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #090968;
  font-size: 15px;
}

// Css for medication progress

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #4bb543;
}

.stepper-item.completed::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #4bb543;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

//end

.flex-conatiner-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

//Lab orders

.table-head-lab-visit {
  background-color: #4682b4;
  position: sticky;
  top: 0;
  z-index: 2;
}

.table-head-th-lab-visit {
  color: #fff !important;
}

.table-lab-visit-ipd-mui {
 max-height: 40rem !important;
}

.table-lab-visit-ipd-order {
  padding-bottom: 0;
  padding-top: 0;
}

//end

// Diagnosis

.ip-diagnosis-filter-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ip-diagnosis-visit-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.ip-diagnosis-form-table-container {
  display: flex;
  flex-direction: row;
  gap: 2%;
  width: 95%;
}

.ip-diagnosis-table {
  width: 48%;
  height: fit-content;
}

.ip-diagnosis-form {
  width: 42%;
  height: fit-content;
  .fieldSet {
    word-wrap: break-word;
    white-space: normal;
  }
}

.ip-diagnosis-form-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  color: #090968;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5%;
}

//End

.ip-visit-view-btn {
  text-transform: capitalize !important;
  font-size: 12px !important;
  text-decoration: underline !important;
}

.MuiTableCell-root {
  font-size: 12px !important;
}

// Treatment Plan

.ip-treatmentPlan-filter-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ip-treatmentPlan-visit-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.ip-treatmentPlan-form-table-container {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  gap: 2%;
  width: 95%;
}

.ip-treatmentPlan-table {
  width: 48%;
  height: fit-content;
}

.ip-treatmentPlan-form {
  width: 42%;
  height: fit-content;
  pointer-events: none;
}

.ip-treatmentPlan-form-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  color: #090968;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5%;
}

//End

// css for medication administration
.medication-administration-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.data-table-administration {
  width: 100%;
  .table-header {
    background-color: #4682b4;
    border: 1px solid #e6e6e6;
    .MuiTableCell-head {
      color: #fff !important;
    }
  }
}

.custom-date-picker-administration {
  .MuiInputBase-input {
    font-size: 12px;
  }
  .MuiInputLabel-root {
    font-size: 12px;
  }
}

.medication-administration-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.administration-visit-dialog-title {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #090968 !important;
}

.administration-visit-dialog-content {
  padding: 10px 24px !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 24rem;
  .MuiInputBase-input {
    font-size: 12px !important;
  }
  .MuiInputLabel-root {
    font-size: 12px;
  }
}

.administration-visit-dialog-actions {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.administration-visit-dialog-actions-btn {
  background-color: #4682b4 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.table-cell-frequency {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.administration-visit-info-icon {
  color: #4682b4;
  font-size: 20px !important;
}
.flexCenter-administration {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.administration-iconBtn {
  padding: unset;
}

.timeline-container-administration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
  width: fit-content !important;

  .timeline-step-administration {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1px 30px;
    background-color: #4682b4;
    color: white;
    border-radius: 25px;
    flex: 1;
    margin-right: 30px;
    font-size: 12px;

    &:last-child {
      margin-right: 0;
    }

    &.completed {
      background-color: #4682b4;
    }

    &:after {
      content: '';
      position: absolute;
      right: -25px;
      top: 50%;
      transform: translateY(-50%);
      border-left: 15px solid #4682b4;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;

      &.completed {
        border-left-color: #4caf50;
      }
    }

    &:last-child:after {
      display: none;
    }
  }
}

.administration-timeline-date {
  font-size: 15px;
  font-weight: 600;
  color: #090968;
}

.administration-timeline-time {
  font-weight: 800;
}

//end
