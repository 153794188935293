.modal {
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 4px;
  border-width: 0;
  padding: 0;
  max-height: 80%;
  max-width: 80%;

  &[open] {
    animation: show 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;

    &.modal-closing {
      animation: hide 200ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }

  &::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.3);
    backdrop-filter: blur(1px);
    animation: none;
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.small-padding {
  padding: 2px 2px; /* Adjust these values as needed */
  height: 20px; /* Adjust this value as needed */
}

.dialog-table-height {
  max-height: 400px;
}

.dialog-head {
  background-color: #4682b4 !important;
  color: #ffffff !important;
  .MuiTableCell-head {
    background-color: #4682b4 !important;
    color: #fff !important;
    padding: 8px;
  }
}

.dialog-button {
  color: #4682b4;
  text-transform: capitalize !important;
}

.dialog-body {
  .MuiTableCell-body {
    padding: 8px;
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
.delete-image {
  width: 12px;
  position: absolute;
  top: 3px;
  right: 3px;
}

// doctor info dialog css

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #173f7c;
  font-size: 14px;
  font-weight: 600;
}

.close-button {
  color: #999;
}

.close-button:hover {
  color: #555;
}

.dialog-title-padding {
  padding: unset;
}

.MuiDialogTitle-root {
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
}

.MuiDialogContent-root {
  padding: 16px;
}

.MuiDialogActions-root {
  padding: 8px 16px;
}

.MuiTableCell-root {
  font-size: 14px;
  padding: 8px;
}

.MuiTableHead-root {
  background-color: #f5f5f5;
}

.MuiTableRow-root {
  &:nth-of-type(even) {
    background-color: #f9f9f9;
  }
}

.MuiButton-root {
  text-transform: none;
}

.dialog-notes {
  fieldset {
    border: none;
  }
  & .MuiInputBase-input {
    font-size: 12px !important;
  }
  & .MuiInputLabel-root {
    font-size: 12px !important;
  }
  & .MuiFormControlLabel-label {
    font-size: 12px !important;
  }
}
