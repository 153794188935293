.feature-container {
  width: 100%;
  padding: 0.6%;
}

.ip-registration-container {
  display: flex;
  flex-direction: column;
}

.ip-heading-section {
  display: flex;
  flex-direction: row;
}

.ip-heading {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  color: #004c91;
}

.ip-search-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 3%;
}

.ip-register-btn {
  background-color: #4682b4 !important;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-transform: capitalize !important;
}
