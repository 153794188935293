.organization-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
}
.organization-icon-content {
    margin-top: 7px !important;
}
