.orders-container {
  .order-buttons {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;

    .order-btn {
      background-color: #ffffff;
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      padding: 4px 8px;
      cursor: pointer;
      border: 1px solid #0d3f6d;
      border-radius: 4px;

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }

    .active-order-btn {
      background-color: #0d3f6d;
      color: #fff;
      border: 1px solid #0d3f6d;
    }
  }
}

details {
  border: 1px solid #0d3f6d;
  border-radius: 6px;
  margin-bottom: 12px;

  summary {
    background-color: #0d3f6d;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
    cursor: pointer;
    border: none;

    span {
      margin-left: 8px;
    }
  }

  .lab-container {
    padding: 6px;
    display: flex;
  }
}

.lab-sidebar {
  display: flex;
  flex-direction: column;
  flex: 0 0 200px;

  .samples {
    display: flex;
    flex-direction: column;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        text-transform: capitalize;
        background-color: #4682b4;
        color: #f8f8f8;
        padding: 6px 10px;
        margin-bottom: 1px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
      }

      .active {
        background-color: #f8f8f8;
        color: #0d3f6d;
      }
    }
  }

  .selected-container {
    display: flex;
    flex-direction: column;

    .selected-sample {
      display: flex;
      flex-direction: column;
      border: 1px solid #808080;
      border-radius: 5px;
      margin-top: 4px;

      .selected-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        background-color: #dedede;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 6px;
        text-transform: uppercase;
      }

      .selected-content {
        padding: 4px;
        font-family: monospace;
        font-size: 12px;
      }
    }

    .save-btn {
      margin-top: 12px;
      align-self: flex-start;
      font-size: 13px;
      background-color: #004c91;
      color: #ffffff;
      border: none;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;

      &:focus-visible {
        outline: black auto 1px;
      }
    }
  }
}

.lab-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 24px;

  .search-wrapper {
    display: flex;

    .search-tests {
      flex: 1;
      padding: 4px 8px;
      margin: 8px 12px;
      max-width: 600px;
    }
  }

  .lab-tests {
    margin-top: 32px;
  }

  .items-container {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .items {
      margin-left: 10px;
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        width: 240px;
        min-width: 240px;
        font-size: 12px;
        background-color: #dedede;
        margin: 0 4px 4px 0;
        padding: 5px 12px;
        border-radius: 4px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .active {
        background-color: #cdcdcd;
        box-shadow: 1px 1px 2px 0 lightgrey;
      }

      .controlled {
        background-color: #ededed;
        border: 1px solid grey;
        opacity: 0.7;
        cursor: default;
      }
    }
  }
}

.heading {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  margin-left: 10px;
  color: #1d5d90;
}

.not-found {
  font-size: 14px;
}

.check {
  height: 16px;
  margin-right: 6px;
  vertical-align: middle;
}

.delete {
  height: 14px;
  cursor: pointer;
}

.packages-container {
  display: flex;
  flex-direction: column;
  padding: 6px;
  font-size: 14px;

  .packages-row {
    display: flex;
    flex-wrap: wrap;

    .package-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px;
      border: 1px solid #808080;
      border-radius: 4px;
      min-width: 160px;
      height: 80px;
      margin-right: 12px;
      cursor: pointer;
      position: relative;

      &.active {
        background-color: #f2f2f2;
      }

      h2 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }

      p {
        font-size: 12px;
        margin: 0;
      }

      .test-count {
        color: #454545;
      }

      .package-price {
        font-weight: 500;
      }

      .package-check {
        position: absolute;
        top: 6px;
        right: 6px;
        height: 16px;
        vertical-align: middle;
      }
    }
  }

  .package-tests {
    margin: 8px 0;
    padding: 4px;
    border: 1px solid #808080;
    border-radius: 2px;
    font-family: monospace;
    font-size: 12px;
  }
}

.packages-btn {
  padding: 4px 8px;
  background-color: #004c91;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:focus-visible {
    outline: black auto 1px;
  }
}

.requested-by {
  align-self: center;
  padding: 5px 8px;
  margin-left: 20px;
}
.lab-search {
  display: flex;
  gap: 15px;
  margin-left: 14px;
}
.search-lab-input {
  border: 1px solid #aeafb5;
  width: 320px;
  padding: 4px;
  border-radius: 4px;
  height: 30px;
}
.not-panels-found {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-left: 13px;
  font-weight: 500;
}

//Orders
.th-mode {
  width: 10%;
}

.th-requested {
  width: 20%;
}

.th-sample {
  width: 20%;
}

.th-test {
  width: 22%;
}

.th-parameters {
  width: 22%;
}

.th-action {
  width: 8%;
}
.mtminus16 {
  margin-top: -16px;
}
.mtminus25 {
  margin-top: -25px;
}
.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}
.createlaborder-table {
  height: 70vh;
}
.laborder-sub-btn {
  text-align: end;
  margin-bottom: 3px;
}
//End
