.medication-container {
  display: flex;

  .medication-search-form {
    flex: 1;

    .medication-search {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      margin-left: 17px;

      input {
        width: 40%;
        max-width: 600px;
        padding: 6px 10px;
        border: 1px solid rgba(56, 56, 56, 0.6);
        border-radius: 4px;
      }

      button {
        align-self: flex-start;
        max-width: 600px;
        margin-top: 8px;
        margin-right: 6px;
        border: none;
        background-color: #0d3f6d;
        color: #ffffff;
        border-radius: 4px;
        padding: 4px 8px;
        cursor: pointer;

        &:focus-visible {
          outline: #89acf3 auto 2px;
          outline-offset: 3px;
        }
      }

      .alternate-btn {
        border: 1px solid #0d3f6d;
        background-color: transparent;
        color: #000000;
      }
    }
  }
}
.druglist-composition {
  font-size: smaller;
  width: 12rem;
}

.not-in-list {
  font-size: medium;
  width: 15rem;
  color: red;
}

.medication-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 500px;
  max-width: 500px;
  margin-left: 20px;

  .medication-detail {
    font-size: 14px;
    min-width: 500px;
    padding: 2px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      font-size: 12px;
      font-style: italic;
    }

    button {
      font-size: 10px;
    }
  }

  .no-prescriptions {
    font-size: 14px;
    margin: 0;
  }
}

.medication-heading {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 12px;
}

.drug-suggestion {
  margin: 6px 8px 0 0;
  padding: 8px;
  background-color: #f9f9f9;
  border: 1px solid grey;
  border-radius: 6px;
  max-width: 600px;

  .search-row {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 6px;

    p {
      margin: 0 8px 0 0;
      color: #565656;
      font-size: 13px;
    }

    .drug-title {
      font-size: 14px;
      color: #232323;
      font-weight: 600;
    }
  }
}
.medications-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.medications-table th {
  font-family: sans-serif;
  font-size: small;
}
.medications-table td {
  font-size: x-small;
  justify-content: flex-start;
}

.medications-table tr {
  border-bottom: 1px solid #ccc;
}

.medicine-btn {
  align-items: center;
  size: xx-small !important;
  margin-right: 90px !important;
}

.medications-table td:nth-child(1) {
  width: 55%;
}

.medications-table td:nth-child(2) {
  width: 20%;
  margin-right: 45px;
}
.medications-table td:nth-child(3) {
  width: 15%;
}
.medications-table td:nth-child(4) {
  width: 1%;
  margin-left: 55px;
}

.medications-table th:nth-child(1) {
  width: 30%;
}
.medications-table th:nth-child(2) {
  width: 35%;
}
.medications-table th:nth-child(3) {
  width: 9%;
}
.medications-table th:nth-child(4) {
  width: 40%;
}
.label-name {
  font-size: 14px;
  margin-left: 5px;
  margin-right: 14px;
  color: #3498db;
}
.medication-input {
  font-size: 12px;
  border: none;
  border-radius: 7px;
  background-color: rgba(255, 281, 255, 0);
}
.admin-medication-form {
  width: 100%;
  font-size: 13px;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid #004c91;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.w44 {
  width: 44px;
}
.w49 {
  width: 49px;
}
.w88 {
  width: 88px;
}
.w94 {
  width: 90px;
}
.w108 {
  width: 108px;
}
.w72 {
  width: 72px;
}
.w75 {
  width: 75px;
}
.w50 {
  width: 50px;
}
.w4 {
  width: 4%;
}
.w6 {
  width: 6%;
}
.w7 {
  width: 7%;
}
.w8 {
  width: 8%;
}
.w9 {
  width: 9%;
}
.w11 {
  width: 11%;
}
.w13 {
  width: 13%;
}
.w16 {
  width: 16%;
}
.w20 {
  width: 20%;
}
.w155 {
  width: 180%;
}
.drug-header {
  background-color: #004c91;
  color: white;
}
.border-none {
  border: none !important;
}
.padding-none {
  padding: none !important;
}
.drug-flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.drug-flexRows {
  display: flex;
  flex-direction: row;
}
.drug-flexColumn {
  display: flex;
  flex-direction: column;
}
.gap7 {
  gap: 7px;
}
.gap10 {
  gap: 10px;
}
.gap17 {
  gap: 17px;
}
.gap18 {
  gap: 18px;
}
.gap19 {
  gap: 19px;
}
.ml20 {
  margin-left: 20px;
}
.ml10 {
  margin-left: 10px;
}
.mt7 {
  margin-top: 7px;
}
.drugTableHead {
  box-sizing: border-box;
  overflow-x: auto;
  height: 100vh;
}
.overflowX {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.overflowX::-webkit-scrollbar {
  display: none;
}

.drugTable {
  overflow-x: auto;
}

.auto-complete-drug {
  font-size: 8px;
}

input[type='checkbox'] {
  accent-color: green;
}
.margin-1rem {
  margin-top: 1rem;
}
