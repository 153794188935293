.sidebarMenu {
  display: flex;
  position: relative;
}
.location-dropdown {
  position: absolute;
  z-index: 999;
  background-color: black;
}
.location-dropdown-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 1rem 2.1rem rgb(0, 0, 0, 0.5);
}

.dropdown-item {
  position: relative;
}

a.dropdown-link {
  display: block;
  width: 150px;
  padding: 10px;
  background-color: white !important;
  color: black !important;
  text-decoration: none;
}

a.dropdown-link.hovered {
  background-color: #d4d7d9 !important;
}

.arrow {
  margin-left: 10px;
}

.sub-dropdown {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white !important;
  box-shadow: 0 1rem 2.1rem rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.sub-dropdown-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sub-dropdown-link {
  display: block;
  width: 150px;
  padding: 10px;
  background-color: white !important;
  color: black !important;
  text-decoration: none;
}
a.sub-dropdown-linkhovered {
  background-color: #d4d7d9 !important;
}

.sub-dropdown-link:hover {
  background-color: #d4d7d9 !important;
}
