.custom-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ag-header-cell-menu-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
}

.filter-icon-container {
  position: relative;
  display: inline-block; 
  top: 3px;
}

.filter-icon {
  font-size: 19px; 
  color: rgba(255, 255, 255, 0.6); 
  background: transparent;
}

/* Red dot appears only if 'with-red-dot' class is added */
.with-red-dot::after {
  content: '';
  position: absolute;
  top: 0; 
  right: 0;
  width: 8px; 
  height: 8px;
  background-color: red;
  border-radius: 50%; 
  border: 2px solid white; 
  transform: translate(50%, -50%);
}
.radiology-collection {
  font-size: 12px;
  .css-1h2sv0j {
    gap: 5px !important;
  }
}
