.painScale-container {
  width: 100%;
  margin-top: 2rem;
}
.painScale-icon {
  font-size: 3.5rem !important;
}

.painScale-innerBox {
  display: flex;
  align-items: center;
  justify-content: start;
}

.painScale-ratingContainer {
  display: flex;
  justify-content: space-between;
}

.painScale-label {
  font-size: 1.5rem;
  text-align: left;
}

.progressNote-issues-header {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #183f7c;
  padding-bottom: 10px !important;
}
.progressNote-symptoms {
  font-size: 12px !important;
  font-weight: 550 !important;
  padding-bottom: 8px !important;
}
.progressNote-subHeader {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.progressNote-subHeader-assesment {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-bottom: 1% !important;
}
.progressNote-symptoms-box {
  font-size: 11px !important;
}
.progressNote-gcs {
  min-width: 16.5rem !important;
}
