.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.form-group label {
  flex: 0 0 150px;
  margin-right: 10px;
}

.form-group input,
.form-group select {
  flex: 1;
  padding: 5px;
}
.form-flex-container {
  display: 'flex';
  flex-direction: column;
}
.form-flex {
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-left: 3%;
  margin-bottom: 5px;
  gap: 10px;
}
.form-grid {
  display: grid;
  padding: 7px;
  gap: 10px;
}
.form-flex label {
  float: left;
  text-align: right;
  margin-top: 3px;
  width: 150px;
  font-size: 12px;
  color: #363463;
}
.form-flex input,
.form-flex select {
  padding: 2px;
  border: 1px solid #adacac;
  border-radius: 4px;
}
.medintervals {
  font-size: 10px;
  max-width: 200px;
  word-wrap: break-word;
}
.createvisit-submitbtn {
  background-color: #4682b4;
  width: 20%;
  color: white;
  margin-left: 42%;
  margin-top: 2%;
}
.lab-rad-table {
  max-height: 350px;
  overflow-y: auto;
}
.opdpatientdata {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: #090968;
  font-size: 15px;
}
.patient-details-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.fixed-section {
  flex: 0 0 auto;
}

.scrollable-section {
  flex: 1 1 auto;
  overflow-y: auto;
}
.opd-table {
  font-size: 15px;
  cursor: pointer;
}
.close-button {
  display: flex;
  justify-content: end;
  margin-right: 1rem;
  cursor: pointer;
}
.mtminus4 {
  margin-top: -4px;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-top: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.h-20 {
  height: 20px;
}
.sl-wd {
  width: 3rem;
}
.service-wd {
  width: 4rem;
}

.ratingIcon {
  color: #183f7c;
}

.statusCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lab-radiology-tab {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.lab-radiology-table {
  font-size: 15px;
  cursor: pointer;
}
.VisitNewTable {
  margin-top: -1rem;
  margin-left: -1rem;
}
.TableHeadFixed {
  position: sticky;
  top: 0px;
  z-index: 9;
}
.EmptyAppointmentText {
  text-align: center;
}
.laborderSlot {
  margin-top: -1rem;
  text-align: end;
}
.registration-card-print {
  display: flex;
  gap: 1rem;
  margin-top: 0.3rem;
}

.opd-medication-dashboard table {
  width: 100%;
  border-collapse: collapse;
}

.opd-medication-dashboard thead {
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  z-index: 1;
}
.datas-table th,
.datas-table td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
  word-wrap: break-word;
  white-space: nowrap;
}
.datas-table thead {
  position: sticky;
  top: 0;
  background: #4682b4;

  z-index: 2; /* Keeps it above other elements */
}
.datas-table tbody,
.datas-table thead tr {
  display: table;
  min-width: 100% !important;
  table-layout: auto; /* Keeps column widths aligned */
}

.table-lab-visit-opd-mui {
  max-height: 21vh;
}
@media (min-width: 1200px) {
  /* XL screen breakpoint */
  .table-lab-visit-opd-mui {
    max-height: 29vh;
  }
}
.table-opd-lab-body {
  overflow: auto;
}

.table-lab-visit-opd-order {
  padding-bottom: 0;
  padding-top: 0;
}
.table-head-lab-opd-visit {
  background-color: #4682b4 !important;
}

.table-head-th-lab-opd-visit {
  color: #fff !important;
  background-color: #4682b4 !important;
}
.medication-container {
  font-size: 10px;
  padding: 0px;
}
.opcasesheet-print {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}
.opcaseSheet-image-content {
  width: 24px;
  height: 24px;
}
.op-visit-dashboard {
  display: flex;
  flex-direction: column;
  margin-bottom:5px
}

.full-height-conatiner {
   height: 100%;
}
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dialog-close-btn {
  display: flex !important;
  justify-content: flex-end !important;
}

.dialog-content {
  margin-top: -2rem;
}
.op-visit-dashboard-height {
  height: 6%;
}
.op-visit-patient-content {
  display: flex;
  flex-direction: column;
  max-height: 83%;
  overflow-y: auto;
}
.opd-search-padding {
  padding: 0px !important;
}
.treatment-plan-dialog {
  height: 80vh;
}
.treatment-plan-icon {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}
.opd-lab-table {
  border: 1px solid #ddd;
}
.opd-medication-container {
  margin-top: 7px;
  margin-bottom: 20px;

}
.opd-screen-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.opd-patient-list-container {
  height:11%;
  margin-bottom:35px
}
.upload-document-icon {
  text-align: center
}
.print-document-icon {
  display: none
}
.pat-print-icon {
  height:20px
}
.opd-dialog-content {
  background: #fff;
  padding:5px !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}