.overall-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  flex-direction: column;
}
.allocation-text {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  font-weight: 700;
}

.info-text-container {
  display: flex;
  align-items: center;
}

.info-text {
  // margin-right: 8px; /* Adjust spacing between text and icon */
  text-align: center;
  font-size: 12px; /* Adjust text size */
}

.info-icon {
  font-size: small; /* Adjust icon size */
  color: #ffffff; /* Change color as needed */
}
.info-icon1 {
  font-size: medium; /* Adjust icon size */
  color: #000000; /* Change color as needed */
}

.allocation-container {
  display: flex;
  justify-content: center;
  padding-top: 3%;
}

.bottom-container {
  background-color: #0d3f6d;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3px;
}

.available,
.occupied,
.waitlist,
.discharge {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.green {
  width: 21px;
  height: 21px;
  fill: green !important;
  margin-top: 3px;
}

.red {
  width: 21px;
  height: 21px;
  margin-top: 3px;
}

.yellow {
  fill: yellow;
  width: 21px;
  height: 21px;
  margin-top: 3px;
}

.blue {
  fill: blue;
  width: 25px;
  height: 25px;
  margin-top: 3px;
}

.input-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label-content {
  color: black;
  margin-top: 3px;
}

.input-context {
  display: flex;
  flex-direction: row;
  background-color: #0d3f6d;
  gap: 50px;
  justify-content: flex-start;
  padding-left: 4%;
  padding-top: 9px;
  padding-bottom: 5px;
}

.input-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.assign-button {
  background-color: #4682b4;
  border-color: none !important;
  padding: 4px;
  color: white;
  font-size: 12px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 7px;
}

.input-flex-content {
  display: flex;
  flex-direction: column;
}

.bottom-text {
  color: white;
  font-size: 14px;
  margin-top: 2px;
}

.inform-text {
  color: black;
  font-size: 14px;
  margin-top: 2px;
}

.hosiptal-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.assign-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4%;
}

.assign-bed-container {
  background-color: #4682b4;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 2%;
  width: 49%;
}

.assign-transfer-container {
  background-color: #4682b4;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 2%;
  width: 60%;
}

.room-container {
  background-color: #0d3f6d;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 2%;
  width: 60%;
}

.uid-search {
  font-size: 12px;
  color: black;
  font-style: oblique;
  font-weight: 500;
  margin-top: 7px;
}

.format {
  font-size: 13px;
  color: white;
  font-style: oblique;
  font-weight: 500;
}

.transfer {
  display: flex;
  gap: 30px;
}

.transfer-button {
  padding: 8%;
  color: #0d3f6d;
  background: white;
  border-color: black;
  font-size: 13px;
}

.sub-container {
  display: flex;
  gap: 10px;
  padding: 4px;
}

.beds-container {
  display: flex;
  gap: 10px;
  height: 80%;
  border-radius: 39px;
  border-color: none;
}
.close-img {
  width: 12px;
  height: 12px;
  object-fit: contain;
}

.event-close {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.bed-image {
  width: 80px !important;
  height: 40px !important;
  cursor: pointer;

  &:hover {
    background-color: #e0ddd8;
  }
}

.header-text {
  font-size: 25px;
  font-weight: 800;
  font-family: ui-monospace;
  color: #d68816;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 5px;
}

.header-container {
  display: flex;
  text-align: center;
  justify-content: center;
}

.available-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  max-width: 650px;
  padding-top: 3%;
}

.bed-action {
  margin-left: 23px;
}

.overall-action {
  height: '90%';
  padding: 3px;
  border-radius: 39px;
  border-color: #d0c7c7;
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.enable {
  color: green;
}

.disable {
  color: red;
}

.hosiptal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.patient-detail {
  font-size: 15px;
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

.allocation {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
  width: 80vw;
}

.addition-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 7%;
}

.addition {
  padding: 4px;
}

.disableBtn {
  opacity: 0.45;
  pointer-events: none;
}

.assign-transfer {
  display: flex;
  gap: 1%;
  border-radius: 4px;
  justify-content: space-between;
}

.transfer-select {
  width: 50%;
  margin-left: 20px;
}

.input-reason-container {
  margin: 7px;
}

.lms-filters {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 10px;

  select {
    border: 1px solid #aeafb5;
    border-radius: 4px;
    margin-right: 8px;
    background-color: #ffffff;
    font-size: 12px;
    padding: 0 4px;
  }

  input[type='search'] {
    flex: 1;
    max-width: 280px;
    font-size: 12px;
    padding: 4px 10px;
    margin-right: 6px;
    border: 1px solid #aeafb5;
    border-radius: 4px;
  }

  input::placeholder {
    font-size: 12px;
    font-style: italic;
  }
}

.alloaction-text {
  font-size: 15px;
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: black;
}

.bed-table {
  width: 100%;
  border-radius: 8px;
}

.bed-table,
.bed-table tr,
.bed-table td,
.bed-table th {
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
}

.bed-table th {
  font-weight: 500;
  background-color: #4682b4;
  color: white;
  font-size: 12px !important;
}

.bed-table th,
.bed-table td {
  padding: 7px;
  font-size: 12px;
}
.bed-font {
  font-size: 14px !important;
}
.f12 {
  font-size: 12px !important;
}
.text-center {
  text-align: center;
  font-size: 12px !important;
}
.text-left {
  text-align: left;
  font-size: 12px !important;
}

.bed-table tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}

.table-fixed {
  table-layout: fixed;
}

.beds-table-wrapper {
  overflow: auto;
  max-height: 500px;
}

.beds-table-body {
  overflow: auto;
}

.bedTableHead {
  overflow-x: auto;
  max-width: 100%;
}

.beds-table-head {
  position: 'sticky';
  top: '0px';
  z-index: '1';
}

.w-40 {
  width: 40%;
}

.admin-bed {
  padding: 4px;
  width: 150px;
  border: 1px solid #004c91;
  border-radius: 4px;
  color: rgb(15, 6, 6);
  background-color: transparent;

  &:focus-visible {
    outline: black auto 1px;
  }
}

.option-content {
  color: black;
}

.uid-container {
  display: flex;
  gap: 10px;
}

.conform-container {
  padding: 5%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.conform-content {
  font-size: 17px;
  font-style: bold;
  text-align: center;
}

.conform-button {
  display: flex;
  flex-direction: row;
  gap: 13px;
  justify-content: center;
}

.conform {
  width: 44%;
  font-size: 12px;
  background-color: #43545c;
  color: white;
}

.edit-btn {
  width: 18px;
}

.btn-edit {
  width: 76% !important;
}
.discharge-text {
  white-space: nowrap;
  margin-top: 8px;
}

.row {
  display: flex;
  flex-direction: row;
}

.flex-container {
  display: flex;
  gap: 1rem;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
  top: 0px;
  z-index: 9;
}

.hover-pointer {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    background-color: #e0ddd8;
    color: red;
  }
}
.no-hover-pointer {
  text-decoration: underline;
  &:hover {
    background-color: #e0ddd8;
    color: red;
  }
}
.hover-occupied {
  display: flex;
  border: none !important;
  cursor: pointer;
  gap: 4px;
}
.doctor-input {
  border: 2px solid black;
}
.edit-doctor {
  border-radius: 6px;
  background: #cbccdc;
}
.span-remarks {
  text-decoration: underline;
  color: blue;
  word-break: break-word;
}
.head-color {
  background-color: #4682b4;
}

.invalid-hover {
  border: none;
}
.w7 {
  width: 7%;
}

.w8 {
  width: 8%;
}

.w12 {
  width: 12%;
}

.w9 {
  width: 9%;
}

.w10 {
  width: 10% !important;
}

.w11 {
  width: 11% !important;
}

.w14 {
  width: 14%;
}
.w15 {
  width: 15%;
}
.w22 {
  width: 22%;
}
.w25 {
  width: 25%;
}
.w20 {
  width: 20%;
}
.list-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.category-filters {
  z-index: 9999;
  position: relative;
  width: 16%;
  background-color: white;
}
.bedselect {
  padding: 3px;
  border-radius: 5px;
  margin-left: 220px;
}
.bedallocate {
  width: 16%;
  padding: 3px;
  border-radius: 5px;
}

.select-route {
  position: relative;
}

.overviewMain {
  position: absolute;
  top: 46px;
}
.btn-primaryBtn {
  background-color: #4682b4;
  color: #fff;
}
.btn {
  border: 0;
  padding: 6px 14px;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 1px 1px 12px #0000001f;
  cursor: pointer;
}
.ml10 {
  margin-left: 10px;
}
.w130 {
  width: 130%;
}
.green {
  background-color: green;
  color: white;
}
.blue {
  background-color: #f47421;
  color: white;
}
.s12 {
  font-size: 12px !important;
}
.w13 {
  width: 13%;
}
.w16 {
  width: 16%;
}
.w100 {
  width: 100%;
}
.w45 {
  width: 45%;
}
.doctor-edit {
  display: flex;
  flex-direction: column;
}
.bed-visit {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.location-visit {
  width: 125px;
  padding: 3px;
  border: 1px solid black;
}
.bottom20 {
  margin-bottom: 20px;
}
.visit-input {
  margin-right: auto;
  margin-left: -8%;
  margin-bottom: 5px;
  display: flex;
}
.input-visit {
  width: 220px;
  padding: 2px;
  margin-left: 2rem;
  border: 1px solid #adacac;
}

.input-visit-autoComplete {
  width: 220px;
  margin-left: 2rem;
}

.visit-input label {
  float: left;
  text-align: right;
  margin-top: 3px;
  margin-left: 10px;
  width: 200px;
  font-size: 14px;
  color: #363463;
}
.form-grid {
  display: grid;
  max-width: '800px';
}
.visit-button {
  background-color: '#4682b4';
  width: '10%';
  color: 'white';
  margin-left: '42%';
  margin-top: '2%';
}
.patient-input {
  border: 1px solid #004c91;
  border-radius: 4px;
  background-color: transparent;
  margin-right: 10px;
  padding: 3px 6px;
  width: 200px;
}
.dashboard-content {
  display: 'flex !important';
  flex-direction: 'row !important';
  justify-content: 'space-between !important';
}
.visit-column {
  display: 'flex';
  flex-direction: 'column';
}
.dashboard-text {
  cursor: pointer;
  color: '#090968';
  text-decoration: 'underLine';
}
.f-15 {
  font-size: '15px';
}
.f12 {
  font-size: '12px';
}
.f13 {
  font-size: 13px;
}
.select-visit {
  padding: '4px';
  border-radius: '5px';
  background-color: 'white';
}
.flex-gap1 {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.flex-end {
  display: flex;
  justify-content: end;
}
.h-300 {
  max-height: 300px;
  overflow-y: auto;
}
.header-view-text {
  cursor: pointer;
  color: #090968;
  text-align: center;
  text-decoration-line: underLine;
  text-decoration-color: #090968;
}
.carousel-height {
  width: 106px !important;
  height: 43px !important;
}
.padding20 {
  padding: 20px;
}
.margin2 {
  margin-top: -2rem;
}
.margin3 {
  margin-top: 3px;
}
.margin10 {
  margin-top: -10px;
}
.flex-grow {
  flex-grow: 1;
}
.flex-display {
  display: flex;
}
.ml5 {
  margin-left: 5px;
}
.mt40 {
  margin-top: 40px !important;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-right: 5px !important;
}
.mt6 {
  margin-top: 6px !important;
}
.mt7 {
  margin-top: 7px !important;
}
.ml15 {
  margin-left: 15px;
}
.input-border {
  border: 1px solid rgb(18, 102, 180);
  border-radius: 5px;
}
.input-context {
  font-size: 14px;
  min-height: 40px;
  padding: 12px 14px;
  background-color: #4682b4;
}
.span-input {
  font-size: 12px;
  color: white;
  padding: 5px;
}
.mt12 {
  margin-top: 12px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mt9minus {
  margin-top: -9px;
}
.mt3rem {
  margin-top: 3rem;
}
.h30 {
  height: 30px;
}
.dashboard-context {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #090968;
  font-size: 15px;
  margin-top: -7px;
}
.content-end {
  display: flex;
  justify-content: flex-end;
}
.flex-center {
  display: flex;
  justify-content: center;
  text-align: center;
}
.ml-auto {
  margin-left: auto !important;
}
.border-none {
  border: none;
  cursor: pointer;
}
.edit-dialog {
  width: 50%;
  padding: 1%;
}
.bg-none {
  border: none;
  background: white;
}
.height18-width20 {
  height: 18px;
  width: 20px;
}
.font15 {
  font-size: 15px;
  cursor: pointer;
}
.fw600 {
  font-weight: 600;
}
.visit-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #090968;
  font-size: 15px;
}
.unique-date-filter {
  padding: 4px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #aeafb5;
}
.bed-assignment-text {
  font-size: 15px;
  font-weight: 600;
  margin-top: 6px;
  color: #090968;
}
.mb2 {
  margin-bottom: 2px;
}
.mtminus23 {
  margin-top: -23px;
}
.mt3 {
  margin-top: 3px;
}
.w105 {
  width: 105px;
}

.cancellation-visit-dialog-title {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #090968 !important;
}
.content-center {
  justify-content: center;
}

.cancellation-visit-dialog-content {
  padding: 0px 24px !important;
}

.cancellation-visit-dialog-textField {
  min-width: 25rem !important;
}

.cancellation-visit-dialog-textField .MuiInputBase-input {
  font-size: 13px !important;
}

.cancellation-visit-dialog-actions {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.cancellation-visit-dialog-actions-btn {
  background-color: #4682b4 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.back-button-cursor-dashboard {
  cursor: pointer !important;
  color: #1976d2 !important;
}
.bed-error-discharge {
  width:14%
}
.bed-error-uhid-container {
  display:flex;
  gap:10
}
.bed-assignemnt-content {
  width:115%
}
