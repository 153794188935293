.sxp_js_chat_title,
.sxp_js_chat_subTitle,
.sxp_js_chat_LangImgBtn,
.sxp_js_chat_makeFullScreen {
  display: none;
}
.sxp_js_chat_chatHeadaer {
  background-color: #004c91;
  padding: 20px 0 15px;
}
.sxp_js_chat_sxpChat {
  box-shadow: 0 2px 10px 1px #787878;
}
.sxp_js_chat_chatMainLogo img {
  width: 154px;
}
.sxp_js_chat_userProfile {
  background-color: #004c91;
}
.sxp_js_chat_chatIcon {
  background-color: #004c91;
  border: 1px solid white;
}
.sxp_js_chat_closeBtn {
  img {
    width: 17px;
  }
}
.sxp_js_chat_chatControl {
  right: 16px;
}
