.timeRangeBlock{
  display: flex;
  flex-direction: row;
  input{
    height: 35px;
    padding-left: 10px;
    border: 1px solid #004c91;
    border-radius: 4px;
    width: 120px;
    margin-right: 4px;
  }
  .react-datepicker-popper{
    .react-datepicker__triangle{
      transform: translate(46px, 0px) !important;
    }
  }
  .selectDuration
  {
    height: 35px;
    padding-left: 10px;
    border: 1px solid #004c91;
    border-radius: 4px;
    width: 120px;
    background-color: transparent;
    margin-right: 4px;
  }
}
.react-datepicker__header {
  background-color: #4682B4;
}
.react-datepicker__current-month{
  color: #fff;
}
.react-datepicker__day-name{
  color: #fff;
}
.createSlotBlock{
  width: 435px;
  margin-top: 10px;
  td{
    button{
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }
}
.customGrid{
  th{
    border: 0 !important;
  }
  td{
    font-size: 13px !important;
    padding: 4px !important;
    border: 0 !important;
  }
}

.slots-loader {
  padding-top: 24px;
  text-align: center;
}

.slot-container {
  display: flex;
  flex-direction: row;
}

.slot-config {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slot-heading {
  font-size: 15px;
  font-weight: 600;
}

.schedule-list {
  display: flex;
  flex-wrap: wrap;

  .schedule-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    background-color: #f0f0f0;
    color: #232323;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    margin-right: 6px;
    margin-bottom: 6px;
    width: 120px;
    max-width: 150px;
    height: 80px;
    cursor: pointer;
    transition: box-shadow 200ms ease-in;

    &:hover {
      box-shadow: 0 2px 5px 0 rgba(70, 70, 70, 0.4);
    }

    p {
      margin: 0;
    }

    .schedule-heading {
      font-size: 14px;
      font-weight: 600;
    }

    .schedule-time {
      font-size: 12px;
      font-weight: 600;
    }

    .schedule-slots {
      font-size: 11px;
      color: #676767;
    }
  }

  .schedule-modal {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 320px;

    .modal-heading {
      margin-top: 0;
      font-size: 14px;
      font-weight: 600;
    }

    .modal-slots {
      width: 300px;
      display: flex;
      flex-wrap: wrap;

      .modal-slot {
        padding: 4px 8px;
        margin-right: 2px;
        margin-bottom: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #026b2f;
        color: #ffffff;
        font-size: 12px;
        border-radius: 4px;
        cursor: default;
      }

      .busy {
        opacity: 0.5;
      }
    }

    .modal-text {
      font-size: 12px;
      color: #343434;
      font-style: italic;
      margin-bottom: 0;
    }

    .modal-buttons {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      .modal-button {
        border: none;
        outline: none;
        padding: 6px 12px;
        font-size: 14px;
        background-color: #0d3f6d;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }
}
