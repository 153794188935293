* {
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.width-minor {
  width: 60px;
}

.inline-block {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}
.table-w-2 {
  width:2%
}
.table-w-3 {
  width: 3%;
}

.table-w-4 {
  width: 4%;
}

.table-w-5 {
  width: 5%;
}

.table-w-6 {
  width: 6%;
}

.table-w-7 {
  width: 7%;
}

.table-w-8 {
  width: 8%;
}
.table-w-9 {
  width: 9%;
}
.table-w-10 {
  width: 10%;
}
.table-w-11 {
  width: 11%;
}
.table-w-12 {
  width: 12%;
}

.table-w-15 {
  width: 15%;
}
.table-w-17 {
  width: 17%;
}

.table-w-20 {
  width: 20%;
}
.table-w-23 {
  width: 23%;
}
.table-w-25 {
  width: 25%;
}
.table-w-27 {
  width: 27%;
}
.table-w-30 {
  width: 30%;
}

.table-w-40 {
  width: 40%;
}

.table-w-50 {
  width: 50%;
}

.m0 {
  margin: 0;
}

.ml0 {
  margin-left: 0;
}

.mr0 {
  margin-right: 0 !important;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}
.mbt10 {
  margin-bottom: 10px;
  margin-top: -2rem;
}
.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mb5 {
  margin-bottom: 5px;
}

.mr5 {
  margin-right: 5px;
}

.ml5 {
  margin-left: 5px;
}

.mt5 {
  margin-top: 5px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr20 {
  margin-right: 20px;
}

.ml20 {
  margin-left: 20px;
}

.mt20 {
  margin-top: 20px;
}

.p0 {
  padding: 0;
}

.pl0 {
  padding-left: 0;
}

.pr0 {
  padding-right: 0;
}

.pt0 {
  padding-top: 0;
}

.pb0 {
  padding-bottom: 0;
}

.flex {
  display: flex;
}

.apart {
  justify-content: space-between;
}

.wrapEle {
  flex-wrap: wrap;
}

.flexgrow1 {
  flex-grow: 1;
}

.pos-rel {
  position: relative;
}

input,
select,
option,
textarea,
button {
  outline: none;
}

.disableBtn {
  opacity: 0.45;
  pointer-events: none;
}

.hideIt {
  display: none;
}

.fontw600 {
  font-weight: 600;
}

.widthP50 {
  width: 50%;
}

.btn {
  border: 0;
  padding: 6px 14px;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 1px 1px 12px #0000001f;
  cursor: pointer;
}

.btn-primaryBtn {
  background-color: #4682b4;
  color: #fff;
}

.layoutcontent {
  padding: 0.5rem;
}
.search-container {
  display: flex;
  justify-content: flex-end;
}

.search-container > * {
  margin-right: 10px;
}

.data-table {
  width: 100%;
  border-radius: 8px;
}

.data-table,
.data-table tr,
.data-table td,
.data-table th {
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
}

.data-table th {
  font-weight: 500;
}

.data-table th,
.data-table td {
  padding: 6px;
  font-size: 12px;
}

.data-table tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}

.table-fixed {
  table-layout: fixed;
}

.upload_div {
  border: 1px solid #d3d3d3;
  text-align: center;
  width: 360px;
  border-radius: 10px;
  margin: 80px auto;
  padding-top: 20px;
  padding-bottom: 38px;

  input {
    display: none;
  }

  .uploadBtn {
    background-color: #fff;
    border: 1px solid #004c91;
    cursor: pointer;
    height: 35px;
    color: #004c91;
    padding: 0 14px;
    box-shadow: 1px 1px 12px #0000001f;
    border-radius: 8px;
    width: 140px;
    margin-bottom: 3px;
  }

  .submitUpload {
    background-color: #004c91;
    border: 1px solid #004c91;
    cursor: pointer;
    height: 38px;
    color: #fff;
    padding: 0 20px;
    box-shadow: 1px 1px 12px #0000001f;
    border-radius: 8px;
    width: 180px;
  }

  .submitUpload:disabled {
    opacity: 0.6;
    cursor: auto;
  }
}

.content-height {
  height: 100vh;
  display:flex;
  flex-direction: column;
}

.locationLayout {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  justify-content: center;
  align-items: center;

  div {
    border: 1px solid #d3d3d3;
    text-align: center;
    width: 360px;
    border-radius: 10px;
    margin: 80px auto;
    padding-top: 20px;
    padding-bottom: 38px;

    select {
      width: 240px;
      height: 38px;
      border-radius: 8px;
      background-color: #fff;
      margin-bottom: 10px;
      padding-left: 10px;
    }

    button {
      background-color: #004c91;
      border: 1px solid #004c91;
      cursor: pointer;
      height: 38px;
      color: #fff;
      padding: 0 20px;
      box-shadow: 1px 1px 12px #0000001f;
      border-radius: 8px;
      width: 240px;
    }
  }
}

.center-layout {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  width:100%;
  align-items: center;

  img {
    height: 80px;
    width: 80px;
  }

  .unauthorized {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .main-btn {
    background-color: #004c91;
    border: none;
    cursor: pointer;
    color: #fff;
    padding: 4px 12px;
    border-radius: 6px;
  }
}

.headerComponent1 {
  z-index: 2002;
  background-color: #183f7c;
  color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;

  .logo {
    padding: 10px 10px 10px 12px;
  }
}

.docsList {
  padding-left: 0;
  margin: 0;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  overflow: auto;
  max-height: 550px;

  li {
    list-style-type: none;
    border-bottom: 1px solid #c8c8c8;
    padding: 4px 4px 4px 10px;
    transition: 0.5s;
    cursor: pointer;

    div {
      position: relative;

      img {
        width: 38px;
        position: absolute;
        border: 1px solid #c0c0c0;
        left: -4px;
        top: 2px;
        padding: 1px;
        border-radius: 50%;
        background-color: #fff;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 0 44px;
      }

      span {
        font-size: 13px;
        font-weight: 400;
        margin-left: 44px;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.docsList li.activeTab {
  background-color: #4682b4;

  p,
  span {
    color: #fff;
  }
}

.calnderLayout {
  .docBlock {
    display: inline-block;
    width: 25%;
    vertical-align: top;
    padding-right: 16px;
  }

  .calenderBlock {
    display: inline-block;
    width: 75%;
  }
}
.rbc-btn-group {
  background-color: #4682b4;
}
.rbc-header {
  background-color: #4682b4;
  height: 2rem;
  font-weight: 1px;
  columns: #fff;
}
.rbc-row-content {
  height: 0rem;
}
.rbc-time-header-cell {
  color: #fff;
}
.rbc-today {
  color: black;
}
.rbc-btn-group button .rbc-active {
  color: black !important;
}
.rbc-toolbar button {
  color: white !important ;
}
.rbc-label {
  font-size: 12px;
}

.datePickerUI {
  padding-left: 10px;
  border: 1px solid #aeafb5;
  border-radius: 4px;
  margin-right: 10px;
  width: 115px;
  height: 30px;
  background-color: transparent;
  background: none !important;
}

.handleCal {
  position: relative;

  img {
    position: absolute;
    z-index: 0;
    width: 18px;
    right: 18px;
    top: 6px;
    cursor: pointer;
  }
}

.editImgBtn {
  border: 0;
  background-color: transparent;
  cursor: pointer;

  img {
    width: 16px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 99;
}

.modalPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 360px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 999;

  .innerModal {
    text-align: center;
    padding-bottom: 20px;
  }

  h4 {
    margin: 0;
    background-color: #004c91;
    color: #fff;
    padding: 12px 12px 12px 15px;
    font-weight: 500;
  }

  p {
    font-size: 13px;
    text-align: center;
    margin-top: 40px;
  }

  button {
    border: 0;
    color: #fff;
    height: 34px;
    cursor: pointer;
    padding: 0 20px;
    margin-top: 20px;
  }

  .okBtn {
    background-color: #004c91;
    margin-right: 4px;
  }

  .close {
    background-color: #999;
  }
}

.appointmentBtn {
  white-space: nowrap;
  fill: #fff;

  img {
    width: 18px;
    height: 3rem;
  }

  button {
    padding: 4px 8px;
  }

  svg {
    width: 18px;
    margin-right: 5px;
    vertical-align: bottom;
  }
}

.doctorSelect {
  width: 100%;
  margin-bottom: 4px;
  display: block;
  padding: 6px 6px 6px 10px;
  border: 1px solid #004c91;
  border-radius: 4px;
  background-color: transparent;
  height: 35px;
}

.timeSlotsChips {
  background-color: #026b2f;
  color: #ffffff;
  cursor: pointer;
  padding: 2px 11px;
  border-radius: 5px;
  margin-right: 3px;
  margin-bottom: 3px;
  flex-wrap: wrap;
  font-size: 13px;
}

.meetingLink {
  background-color: #026b2f;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 11px;
  border: none;
  outline: none;
  cursor: pointer;
}

.visitLink {
  background-color: #c3d7cb;
  margin-left: 4px;
  color: #232323;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 11px;
  border: none;
  outline: none;
  cursor: pointer;
}

.cap-text {
  text-transform: capitalize;
}

.text-small {
  font-size: 13px !important;
}

.appointments-search {
  width: 300px;
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #aeafb5;
  border-radius: 4px;
  background-color: transparent;

  &::placeholder {
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
  }
}

.app-loader {
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.patient-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.patient-filter-btn {
  display: flex;
  justify-content: flex-end;
}
.create-visit-btn {
  padding-bottom: 3%;
}
.patient-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
}
.patient-status {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.react-datepicker__header {
  background-color: #4682b4;
}
.OPDScreenInvoice {
  padding: 2px 4px;
  background-color: teal;
  color: #fff;
  border-radius: 2;
  margin-left: 6;
  font-size: 11;
  cursor: pointer;
}
.printbtn {
  margin-bottom: 0.5rem;
}
.printbtn img {
  height: 1.5rem;
  width: 15px;
}
.uploadbtn img {
  width: 25px;
  height: 20px;
}
.interpretation {
  word-wrap: break-word;
}
