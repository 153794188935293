$pcolor: #92838c;
$icolor: #fff;
$bradius: 4;
$length: 70;
$ocolors: (
  /* Odoo colors      */ oorange: (#cc7039, #da956b),
  opink: (#ca5377, #cd7690),
  oyellow: (#b5aa59, #cdc484),
  ogray: (#545554, #797c79),
  oteal: (#218689, #269396),
  oblue1: (#6a9eba, #94b6c8),
  oblue2: (#626584, #797da5),
  ored: (#7c3838, #b06161),
  ogreen: (#5f8a71, #7cc098)
);

@mixin border-radius($bradius) {
  -webkit-border-radius: $bradius + px;
  -moz-border-radius: $bradius + px;
  -ms-border-radius: $bradius + px;
  border-radius: $bradius + px;
}

.icon-box {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: $length + px;
  height: $length + px;
  font-size: $length/2 + px;
  line-height: $length + 3 + px;
  @include border-radius($bradius);
}
@each $ocolors, $i in $ocolors {
  .#{$ocolors} {
    background: linear-gradient(45deg, $i);
  }
}

.icon-box:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  @include border-radius($bradius);
  -webkit-box-shadow: inset 0 1px 0px 0px rgba(255, 255, 255, 0.38),
    inset 0 -1px 0px 0px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: inset 0 1px 0px 0px rgba(255, 255, 255, 0.38),
    inset 0 -1px 0px 0px rgba(0, 0, 0, 0.38);
  box-shadow: inset 0 1px 0px 0px rgba(255, 255, 255, 0.38),
    inset 0 -1px 0px 0px rgba(0, 0, 0, 0.38);
}

@mixin long-shadow($scolor, $length) {
  $list: ();
  @for $i from 1 through $length {
    $list: append($list, $scolor -#{$i}+ px $i + px, comma);
  }
  text-shadow: $list;
}

.icon-box span {
  display: block;
  height: $length + px;
  width: $length + px;
  color: $icolor;
}
@each $ocolors, $i in $ocolors {
  .#{$ocolors} {
    span {
      @include long-shadow(
        mix(mix(nth($i, 1), nth($i, 2), 75%), #393939, 69%),
        $length
      );
    }
  }
}

.icons_All {
  text-align: center;
  justify-content: center;
  margin: auto;
  width: 45%;
  padding-top: 30px;
  column-gap: 3rem;
  row-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.paragragh_icon {
  color: white;
  margin-top: 0rem;
  font-size: 12px;
  font-weight: 500;
}
.myicons .odoo-image {
  height: 63%;
  max-width: 5.8rem;
  transition: box-shadow 0.3s ease;
  transition: transform 0.2s;
}

.myicons .odoo-image:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 9px 3px;
  transform: scale(1.1);
}
