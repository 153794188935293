.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 0.5px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.column {
  flex: 1;
}
.column11 {
  flex-basis: 91.66%;
}
.column5 {
  flex-basis: 41.66;
}
.column6 {
  flex-basis: 50%;
}
.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}
.textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
}
.input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
.fieldset {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}
.legend {
  padding: 0 8px;
}
.radioLabel {
  display: block;
  margin-bottom: 8px;
}
.font-12 {
  font-size: 0.5 rem;
}
.justify-content {
  justify-content: flex-start;
}
.flex-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}
.f12-m200 {
  font-size: 12px;
  margin-right: 200px;
}
.ml10 {
  margin-left: 10px;
}
.f-05rem {
  font-size: 0.5rem;
}
