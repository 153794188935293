.submitBtn:disabled {
  opacity: 0.6;
  cursor: auto;
}
.locationDataSize {
  background-color: #183f7c;
  color: white;
  font-size: 68%;
  width: 7.5vw;
}
