.full-usercreation-form {
  max-width: 400px;
  margin: 0 auto;
}

form .form-group label {
  font-size: 14px;
  width: 30%;
  display: inline-block;
  text-align: right;
  color: #363463;
  font-size: 14px;
  padding-right: 10px;
  margin-top: 8px;
}
.form-group {
  margin-bottom: 0.5rem !important;
}

form .form-group input,
form .form-group select {
  width: 100px;
  border: 1px solid #ddd;
  font-size: 12px;
  height: 30px;
  background-color: #fff;
}

form .form-group.inline {
  display: flex;
  justify-content: space-between;
}

form .form-group.inline div {
  flex: 1;
  margin-right: 10px;
}

form .form-group.inline div:last-child {
  margin-right: 0;
}

form button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
}

form button:hover {
  background-color: #0056b3;
}

.test-search {
  display: flex;
  justify-content: space-between;
}
.button-container-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.userform-selectedRoles {
  height: 60px;
}
.userform-submit-btn {
  width: 20%;
  background-color: #4682b4;
}
.userform-checkbox {
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.userKeylockID {
  cursor: pointer;
  font-size: 9px;
}
.UserDate {
  font-size: 8px;
}
.editSubmitCancelBtn {
  display: flex;
  justify-content: end;
  gap: 1rem;
}
.rolestitlefontsize {
  font-size: 0.9rem;
}
.rolesfontsize {
  font-size: 0.88rem;
}
.rolelist {
  display: flex;
  align-items: center;
}
.checkboxsize {
  transform: scale(0.8);
}
.dialogbutton {
  display: flex;
  justify-content: space-between;
}

.role-list-container {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 50vh;
  padding-right: 8px;
}

.fixed-buttons {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
}
.role-list-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.role-list-container li {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.custom-select {
  height: 80px;
  width: 60%;
  overflow-y: auto;
  border: 1px solid #ccc;
}
.role-item {
  font-size: 11px;
  cursor: pointer;
  padding-left: 2%;
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s ease;
}

.role-item.selected {
  background-color: #007bff;
  color: #fff;
}
