.form-wrapper {
  position: relative;

  .profile-container {
    position: absolute;
    top: 2px;
    right: 40px;
    height: 200px;
    width: 200px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;

    .delete-image {
      top: 8px;
      right: 6px;
      height: 20px;
      width: 20px;
      padding: 2px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      .delete {
        object-fit: cover;
      }
    }

    .profile {
      border: 4px solid lightgrey;
      width: 70px !important;
      height: 70px !important;
      object-fit: contain;
    }

    .controls {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 4px;

      .camera,
      .upload {
        padding: 4px;
        height: 40px;
        width: 40px;
        background-color: #ededed;
        margin-bottom: 4px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}

.camera-wrapper {
  display: flex;
  padding: 1rem;
  border-radius: 6px;

  .capture,
  .confirm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;

    img {
      height: 200px;
      width: 200px;
      object-fit: contain;
    }

    button {
      margin-top: 12px;
      background-color: #0d3f6d;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      cursor: pointer;
    }

    button:disabled {
      cursor: default;
      opacity: 0.6;
    }
  }
}

.upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  img {
    margin-top: 12px;
    border-radius: 12px;
    height: 200px;
    width: 200px;
    object-fit: contain;
  }

  button {
    margin-top: 8px;
    background-color: #0d3f6d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
  }

  button:disabled {
    cursor: default;
    opacity: 0.6;
  }

  .error-message {
    margin-top: 8px;
    font-size: 11px;
    font-style: italic;
    color: red;
  }
}
.admin {
  width: 60px;
}
.admin-forty {
  width: 40px;
}

.photo-container {
  position: absolute;
}
.overall-container {
  position: 'relative';
}
.registration-back-icon {
  margin-top: 13px !important;
  margin-left: 8px !important;
}
.registration-back-btn {
  font-size: 20px;
  color: blue;
}
.patient-registration-container {
  width:100%
}
.keyboard-space-icon {
  font-size: 20;
   color: blue;
   margin-top: -8px
}
.steps-content {
  width: 40%;
   text-align: right;
   margin-top: 16px 
}